import React, { useContext, useState } from "react";
import { Box, Flex, Text, TransparentButton, Divider } from "flicket-ui";
import { Icon, Status } from "~components";
import { BaseContext } from "~context";
import { Layout } from "~components/common/Layout";
import { EventQuery } from "~graphql/sdk";
import { Desktop } from "./detail.Desktop";
import { Mobile } from "./detail.Mobile";
import { useIsMobile } from "~hooks";
import MainCTA from "../events.MainCTA";
import useShowReleaseCodes from "~features/events/hooks/useShowReleaseCodes";
import useDetailsPageContext from "~features/events/hooks/useDetailsPageContext";
import { Release } from "~graphql/fetchers";
import { useActiveCompetitions } from "~features/events/hooks/useActiveCompetitions";
import ReleaseCodesModal from "../ReleaseCodesModal";

export type DetailsProps = {
  event: EventQuery["event"];
  release?: Release;
};

export const Detail = ({ event, release }: DetailsProps) => {
  const { activeCompetitions, isLoading } = useActiveCompetitions(event.id);
  const pageContext = useDetailsPageContext(event, release, activeCompetitions);
  const [activeModal, setActiveModal] = useState<"releaseCodes">();

  const isMobile = useIsMobile();
  const { isSinglePage } = useContext(BaseContext);
  const showReleaseCodes = useShowReleaseCodes(event.enableReleaseCodes);

  const footerHeight = showReleaseCodes ? "110px" : "70px";

  return (
    <Status loading={isLoading} error={false}>
      {isMobile && (
        <>
          <Box
            d={{ _: "flex", md: "none" }}
            flexDirection="column"
            flex={1}
            pt={isSinglePage ? 15 : 0}
          >
            {!isSinglePage && (
              <>
                <Flex px={2} py={2} alignItems={"center"}>
                  <TransparentButton href="/events">
                    <Icon icon="chevron-left" fontSize={6} />
                  </TransparentButton>
                  <Text
                    lineHeight="normal"
                    fontSize={3}
                    fontWeight="extraBold"
                    color="N800"
                    ml={2}
                  >
                    {event.title}
                  </Text>
                </Flex>
                <Divider />
              </>
            )}
            <Layout
              pt={{ _: "0 !important" as any, md: 8 }}
              paddingBottom={footerHeight as any}
              layoutType="sub"
            >
              <Mobile pageContext={pageContext} />
            </Layout>
            <Flex
              height={footerHeight}
              flexDirection={"column"}
              justifyContent={"center"}
              position={"fixed"}
              bottom={0}
              left={0}
              right={0}
              px={2}
              py={1}
              background="white"
              borderTop={"1px solid"}
              borderColor={"N200"}
            >
              <MainCTA pageContext={pageContext} />
              {showReleaseCodes && (
                <TransparentButton
                  onClick={() => setActiveModal("releaseCodes")}
                >
                  <Text
                    fontWeight={"extraBold"}
                    fontSize={3}
                    color="N800"
                    my={"6/4"}
                  >
                    Have a release code?
                  </Text>
                </TransparentButton>
              )}
            </Flex>
          </Box>
        </>
      )}
      {!isMobile && (
        <Box d={{ _: "none", md: "flex" }} flexDirection="column" flex={1}>
          <Layout layoutType="main">
            <Desktop
              pageContext={pageContext}
              event={event}
              isSinglePage={isSinglePage}
            />
          </Layout>
        </Box>
      )}

      <ReleaseCodesModal
        isOpen={activeModal === "releaseCodes"}
        onClose={() => setActiveModal(undefined)}
        event={event}
      />
    </Status>
  );
};
